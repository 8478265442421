import { useAuthStore } from '~/store/auth';

const EXCEPTION_NO_JWT = 'needs_login';
const EXCEPTION_UNABLE_TO_FETCH_USER = 'unable_to_fetch_user';

export default defineNuxtRouteMiddleware(async(to) => {
    const authStore = useAuthStore();

    const cookie = await useJwtToken();

    try {
        if (!cookie.value) {
            throw EXCEPTION_NO_JWT;
        }

        const response = await authStore.refreshJwtToken();

        if (response.success && response.token) {
            cookie.value = response.token;
            authStore.jwtToken = response.token;
    
            const user = await authStore.fetchUser().catch(() => false);

            if (!user) {
                throw EXCEPTION_UNABLE_TO_FETCH_USER;
            }
        }
    } catch (e: unknown) {
        if (typeof e !== 'string') {
            console.error(e);
        } else if (e === EXCEPTION_UNABLE_TO_FETCH_USER && cookie.value) {
            cookie.value = null;
        }

        authStore.setInitialPageCookie(to.path);

        return navigateTo('/mijn-tln/login', {
            replace: true
        });
    }
});
